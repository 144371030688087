import LoadingScreen from '@/pages/loading-screen'
import Routes from '@/routes'

export default function App() {
  return (
    <>
      <LoadingScreen/>

      <Routes />
    </>
  )
}