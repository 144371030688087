export function roundToPlaces(number: number, places: number) {
  const multiplier = 10 ** places
  return Math.round(number * multiplier) / multiplier
}

export function parseNumber(val: number | string | null | undefined): number | null {
  if (typeof val === 'number')
    return val
  if (val === null || val === undefined)
    return null

  const sanitized = val.replace(',', '.')
  return parseFloat(sanitized)
}