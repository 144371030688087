import { DetailLine } from "@/pages/expense-page/expense-page"
import type { Expense, ExpenseItem, UserProfile } from "@/types"
import { firstNameLastInitialIfNeeded, toCurrency } from "@/utils/string-utils"
import { ChevronDownIcon } from "@heroicons/react/16/solid"
import { useMemo, useState } from "react"

const IsBalancesExpandedLocalStorageKey = "isBalancesExpanded"

export default function Balances(props: {
  expense: Expense
  items: ExpenseItem[]
  users: UserProfile[]
  tipPercent: number
}) {
  const { expense, items, users, tipPercent } = props
  const [isExpanded, setIsExpanded] = useState(localStorage.getItem(IsBalancesExpandedLocalStorageKey) === "true")

  const balances = useMemo(
    () =>
      users.map((user) => {
        const subtotal = items.reduce((total, item) => {
          const userIds = item.userIds ?? []
          if (userIds.length === 0) return total

          const userItemShares = userIds.filter((id) => id === user.id).length
          return total + userItemShares * (item.total / userIds.length)
        }, 0)
        const tax = (subtotal / (expense.subtotal ?? 1)) * (expense.tax ?? 0)
        const tip = (tipPercent * subtotal) / 100

        return {
          user,
          subtotal,
          tax,
          tip,
          total: subtotal + tip + tax,
        }
      }),
    [expense, items, users, tipPercent],
  )

  const unclaimed = useMemo(() => {
    let count = 0

    const subtotal = items.reduce((total, item) => {
      const isUnclaimed = !item.userIds?.length
      if (isUnclaimed) count++
      return isUnclaimed ? item.total + total : total
    }, 0)

    const tax = (subtotal / (expense.subtotal ?? 1)) * (expense.tax ?? 0)
    const tip = (tipPercent * subtotal) / 100

    return { count, total: subtotal + tax + tip, subtotal, tax, tip }
  }, [expense, items, tipPercent])

  const handleOpenCloseButtonClick = () => {
    setIsExpanded((s) => {
      if (s) {
        localStorage.removeItem(IsBalancesExpandedLocalStorageKey)
      } else {
        localStorage.setItem(IsBalancesExpandedLocalStorageKey, "true")
      }
      return !s
    })
  }

  return (
    <>
      {isExpanded && (
        <div>
          <div className="text-end text-gray-400 text-xs pr-4"> (subtotal + tax + tip)</div>
          {balances.map((b) => {
            const name = firstNameLastInitialIfNeeded(b.user, users)
            return (
              <DetailLine
                name={name + (b.total < 0 ? " is owed" : " owes")}
                details={b.total > 0 && <span>({[b.subtotal, b.tax, b.tip].map(toCurrency).join(" + ")})</span>}
                key={b.user.id}
              >
                {toCurrency(Math.abs(b.total))}
              </DetailLine>
            )
          })}

          {unclaimed.total > 0 && (
            <DetailLine
              name={`${unclaimed.count} unclaimed`}
              className="text-red-600"
              details={`(${[unclaimed.subtotal, unclaimed.tax, unclaimed.tip].map(toCurrency).join(" + ")})`}
            >
              {toCurrency(unclaimed.total)}
            </DetailLine>
          )}
        </div>
      )}

      <button
        type="button"
        className="text-sm flex items-center justify-center text-gray-500"
        onClick={handleOpenCloseButtonClick}
      >
        <span>{isExpanded ? "Hide" : "Show"} balances</span>
        <ChevronDownIcon className={`w-6 h-6 transition-transform ${isExpanded ? "rotate-180" : "rotate-0"}`} />
      </button>
    </>
  )
}
