import { AnimatePresence, motion } from "framer-motion"
import type { ReactNode } from "react"

export type SheetProps = {
  isOpen: boolean
  onClose: () => void
  children?: ReactNode
}

export default function Sheet(props: SheetProps) {
  return (
    <AnimatePresence>
      <motion.div
        className="flex flex-col fixed w-screen h-screen backdrop-blur bg-white/75 top-[100vh]"
        animate={props.isOpen ? { top: 0 } : {}}
        transition={{ ease: [0, 0, 0.205, 1.005] }}
        exit={{ top: "100vh" }}
      >
        {props.children}
      </motion.div>
    </AnimatePresence>
  )
}
