import type { UserProfile } from "@/types.ts"
import { useMemo } from "react"

export default function Avatar(props: { user: UserProfile | null }) {
  if (!props.user) return null

  const initials = useMemo(() => {
    const first = props.user?.firstName?.[0] ?? ""
    const last = props.user?.lastName?.[0] ?? ""
    return first + last
  }, [props.user])

  return (
    <div className="rounded-full border w-8 h-8 flex items-center justify-center shrink-0 relative overflow-hidden">
      {initials}
      <div
        className="bg-center bg-contain absolute top-0 right-0 w-full h-full"
        style={{ backgroundImage: `url(${props.user.imageUrl})` }}
      />
    </div>
  )
}
