import { getGenerativeModel, getVertexAI } from 'firebase/vertexai-preview'
import { Part } from '@google-cloud/vertexai'
import { app } from '@/services/firebase.ts'

const vertexAi = getVertexAI(app);
const model = getGenerativeModel(vertexAi, { model: "gemini-1.5-flash" });

async function fileToGenerativePart(file: File): Promise<Part> {
  const base64EncodedDataPromise = new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string')
        resolve(reader.result.split(',')[1])
    };
    reader.readAsDataURL(file);
  });

  return {
    inlineData: {
      data: await base64EncodedDataPromise as string,
      mimeType: file.type
    },
  };
}

export async function runPromptWithImage(prompt: string, imageFile: File) {
  const imagePart = await fileToGenerativePart(imageFile);

  const result = await model.generateContent([prompt, imagePart]);

  const response = result.response;
  const text = response.text();
  console.log('response', response)
  return text
}
