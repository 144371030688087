import Sheet, { type SheetProps } from "@/components/sheet.tsx"
import { addExpenseMember, removeExpenseMember } from "@/services/expense-service.ts"
import { createUserProfile } from "@/services/user-profile-service.ts"
import { addFriend, useFriends } from "@/services/user-service.ts"
import type { CreateUserProfileDto, Expense } from "@/types.ts"
import { Check, ChevronLeft } from "@mui/icons-material"
import { type ChangeEvent, type MouseEvent, type ReactNode, useCallback, useMemo, useState } from "react"

export default function UserManagementScreen(props: SheetProps & { expense: Expense }) {
  const [isAddMemberScreenOpen, setIsAddMemberScreenOpen] = useState(false)
  const { data: friendProfiles } = useFriends()

  const handleUserClicked = async (user: User) => {
    const isRemovingMember = props.expense.memberIds.includes(user.id)
    if (isRemovingMember) {
      await removeExpenseMember(props.expense.id, user.id)
    } else {
      await addExpenseMember(props.expense.id, user.id)
    }
  }

  const handleAddMemberClick = () => {
    setIsAddMemberScreenOpen(true)
  }

  return (
    <Sheet {...props}>
      <div className="flex justify-between py-4 px-2 border-b-[0.33px] border-black/30">
        <button type="button" onClick={props.onClose}>
          <ChevronLeft /> Back
        </button>

        <h1>Manage tab members</h1>

        <button type="button" onClick={handleAddMemberClick}>
          Add member
        </button>
      </div>

      <div className="bg-white flex-grow">
        <List>
          {friendProfiles?.map((profile) => (
            <ListItem
              checked={props.expense.memberIds.includes(profile.id)}
              value={profile}
              key={profile.id}
              onClick={handleUserClicked}
            >
              {profile.firstName} {profile.lastName}
            </ListItem>
          ))}
        </List>
      </div>

      <AddMemberScreen
        expense={props.expense}
        isOpen={isAddMemberScreenOpen}
        onClose={() => setIsAddMemberScreenOpen(false)}
      />
    </Sheet>
  )
}

function List(props: { children: ReactNode }) {
  return <ul className="pl-4 py-4">{props.children}</ul>
}

function ListItem<TVal = undefined>(props: {
  children: ReactNode
  checked?: boolean
  value?: TVal
  onClick?: (value: TVal, evt: MouseEvent) => void
}) {
  const handleClick = useCallback(
    (evt: MouseEvent) => props.onClick?.(props.value as TVal, evt),
    [props.onClick, props.value],
  )

  return (
    <li
      className="flex items-center justify-between gap-2 py-2 border-b last:border-b-0 cursor-pointer"
      onClick={handleClick}
    >
      {props.children}
      {props.checked && <Check className="mr-4 text-blue-700" />}
    </li>
  )
}

interface User {
  id: string
  firstName: string
  lastName: string
}

function AddMemberScreen(props: SheetProps & { expense: Expense }) {
  const [newMember, updateNewMember, , resetNewMember] = useStateObject<Omit<CreateUserProfileDto, "id">>({
    firstName: "",
    lastName: "",
    imageUrl: null,
    claimed: false,
    createdBy: "1",
  })

  const handleSubmitClick = async () => {
    const createdProfile = await createUserProfile(newMember)
    await addFriend(createdProfile.id)
    await addExpenseMember(props.expense.id, createdProfile.id)
    props.onClose()
    resetNewMember()
  }

  return (
    <Sheet {...props}>
      <div className="flex justify-between py-4 px-2 border-b-[0.33px] border-black/30">
        <button type="button" onClick={props.onClose}>
          <ChevronLeft /> Cancel
        </button>

        <h1>Add new friend</h1>

        <button type="button" className="font-bold" onClick={handleSubmitClick}>
          Add
        </button>
      </div>

      <div className="bg-white flex-grow">
        <List>
          <TextFieldListItem
            label="First name"
            value={newMember.firstName}
            onChange={(firstName) => updateNewMember({ firstName })}
          />
          <TextFieldListItem
            label="Last name"
            value={newMember.lastName}
            onChange={(lastName) => updateNewMember({ lastName })}
          />
          {/*<TextFieldListItem label="Phone #" value={"Aimee"} />*/}
        </List>
      </div>
    </Sheet>
  )
}

function TextFieldListItem(props: { label: string; value: string; onChange: (value: string) => void }) {
  const fieldId = useMemo(() => `field-${Math.random()}`, [])
  const handleChange = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => props.onChange(evt.target.value),
    [props.onChange],
  )

  return (
    <ListItem>
      <label htmlFor={fieldId} className="w-[100px]">
        {props.label}
      </label>
      <input
        className="grow h-10 pl-2 mr-2"
        id={fieldId}
        value={props.value}
        onChange={handleChange}
        autoComplete="off"
      />
    </ListItem>
  )
}

function useStateObject<T>(initialValue: T) {
  const [value, setValue] = useState(initialValue)

  const updateValue = useCallback((updates: Partial<T>) => {
    setValue((val) => ({ ...val, ...updates }))
  }, [])

  const reset = useCallback(() => setValue(initialValue), [initialValue])

  return [value, updateValue, setValue, reset] as const
}
