import { roundToPlaces } from "./number-utils"

export function toCurrency(amount: number | undefined | null): string {
  if (amount === null || amount === undefined) return ""

  const rounded = `$${roundToPlaces(amount, 2)}`
  const needsExtraZero = rounded.match(/\.\d$/)
  return needsExtraZero ? `${rounded}0` : rounded
}

type Named = { firstName: string; lastName: string }

export function fullName(user: Named): string {
  return `${user.firstName} ${user.lastName}`
}

export function firstNameLastInitialIfNeeded(user: Named, allUsers: Named[]): string {
  const isFirstNameUnique = allUsers.filter((u) => u.firstName === user.firstName).length === 1
  return `${user.firstName}${isFirstNameUnique ? "" : ` ${user.lastName[0]}.`}`
}
