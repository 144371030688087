import Avatar from "@/components/avatar.tsx"
import type { ExpenseItem, UserProfile } from "@/types.ts"
import { toCurrency } from "@/utils/string-utils.ts"
import { type Variants, motion } from "framer-motion"

type Props = {
  item: ExpenseItem
  users: UserProfile[] | null
  variants?: Variants
  onClick: () => void
}

export function ReceiptItem(props: Props) {
  const { name, total, userIds } = props.item
  const splitCount = userIds?.length ?? 0
  const perPersonCost = Math.round((total / splitCount) * 100) / 100
  const splitUsers = props.item.userIds?.map((id) => props.users?.find((m) => m.id === id)).filter((m) => !!m)
  const quantity = props.item.quantity ?? 1

  return (
    <motion.li
      className="ml-4 list-none flex items-stretch border-b last:border-b-0"
      variants={props.variants}
      onClick={props.onClick}
    >
      <div className="grow py-2">
        <div>
          {quantity > 1 && `${quantity} `}
          {name}
        </div>
        <div className="text-sm text-gray-400">
          <span>${total}</span>
          {splitCount > 1 && (
            <>
              {" • "}
              <span>{toCurrency(perPersonCost)}/person</span>
            </>
          )}
        </div>
      </div>

      {splitCount === 0 && (
        <div className="flex items-stretch">
          <button type="button" className="text-st-blue px-4 py-2">
            Claim
          </button>
        </div>
      )}

      {splitCount > 0 && (
        <div className="flex items-center pr-4">
          {splitUsers?.map((member) => member && <Avatar user={member} key={member.id} />)}
        </div>
      )}
    </motion.li>
  )
}
