import Avatar from "@/components/avatar"
import PageHeader from "@/components/page-header"
import Balances from "@/pages/expense-page/balances.tsx"
import { ReceiptItem } from "@/pages/expense-page/receipt-item"
import UserManagementScreen from "@/pages/expense-page/user-management-screen.tsx"
import { updateExpense, updateExpenseItem, useExpenseData } from "@/services/expense-service"
import type { ExpenseItem, UserProfile } from "@/types"
import { roundToPlaces } from "@/utils/number-utils.ts"
import { firstNameLastInitialIfNeeded, fullName, toCurrency } from "@/utils/string-utils"
import { ChevronUpDownIcon, UserGroupIcon } from "@heroicons/react/16/solid"
import { ChevronRight } from "@mui/icons-material"
import classNames from "classnames"
import { type Variants, motion } from "framer-motion"
import { DateTime } from "luxon"
import { type ComponentProps, type ReactNode, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

const listAnimationVariants: Variants = {
  initial: {
    opacity: 0,
    x: -15,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: { staggerChildren: 0.05 },
  },
}

type SelectedUser = UserProfile | "everyone"

export default function ExpensePage() {
  const { id } = useParams()
  const { expense, items, members } = useExpenseData(id ?? null)
  const [selectedUser, setSelectedUser] = useState<SelectedUser | null>(null)
  const [isUserManagementScreenOpen, setIsUserManagementScreenOpen] = useState(false)

  useEffect(() => {
    if (!selectedUser) setSelectedUser(members?.[0] ?? null)
  }, [selectedUser, members])

  if (!expense) return null

  const tipPercent = ((expense.tip ?? 0) / (expense.subtotal ?? 1)) * 100

  const handleItemClick = async (item: ExpenseItem) => {
    if (!selectedUser) return
    if (selectedUser === "everyone") {
      const isRemovingEveryone = item.userIds?.length === members?.length
      const userIds = isRemovingEveryone ? [] : members?.map((u) => u.id) ?? []
      await updateExpenseItem(expense.id, item.id, { userIds })
    } else {
      const isRemovingMember = item.userIds?.includes(selectedUser.id)
      const userIds = isRemovingMember
        ? item.userIds?.filter((id) => id !== selectedUser.id)
        : [...(item.userIds ?? []), selectedUser.id].sort()

      await updateExpenseItem(expense.id, item.id, { userIds })
    }
  }

  const dateLabel = !expense.date
    ? null
    : DateTime.fromJSDate(expense.date?.toDate()).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)

  return (
    <div className="bg-gradient-to-r from-st-green to-st-blue min-h-full font-abel">
      <div className="flex flex-col relative z-0">
        <PageHeader
          name={expense.name ?? ""}
          backLabel="< Done"
          subHeader={
            <span>
              {dateLabel} • {items?.length ?? 0} items
            </span>
          }
        />

        <div>
          <div className="flex flex-col gap-2 backdrop-blur bg-white/75 py-2 text-sm">
            <DetailLine name="Paid by" onChange={(paidBy) => updateExpense(expense.id, { paidBy })}>
              {expense.paidBy ?? "?"}
            </DetailLine>
            <DetailLineNumber name="Subtotal" onChange={(subtotal) => updateExpense(expense.id, { subtotal })}>
              {toCurrency(expense.subtotal)}
            </DetailLineNumber>
            <DetailLineNumber name="Tax" onChange={(tax) => updateExpense(expense.id, { tax })}>
              {toCurrency(expense.tax)}
            </DetailLineNumber>
            <DetailLineNumber name="Tip" onChange={(tip) => updateExpense(expense.id, { tip })} value={expense.tip}>
              {((expense.tip ?? 0) > 0 ? `${roundToPlaces(tipPercent, 1)}% • ` : "") + toCurrency(expense.tip)}
            </DetailLineNumber>
            <DetailLineNumber
              name="Total"
              className="font-bold text-st-green"
              onChange={(total) => updateExpense(expense.id, { total })}
            >
              {toCurrency(expense.total)}
            </DetailLineNumber>

            <Balances expense={expense} items={items ?? []} users={members ?? []} tipPercent={tipPercent} />
          </div>

          <motion.div
            className="bg-white grow pb-20"
            variants={listAnimationVariants}
            initial="initial"
            animate="animate"
          >
            {items?.map((item) => (
              <ReceiptItem
                item={item}
                key={item.ref.id}
                users={members ?? null}
                variants={listAnimationVariants}
                onClick={() => handleItemClick(item)}
              />
            ))}
          </motion.div>
        </div>

        <UserSelectionBar
          users={members ?? []}
          selectedUser={selectedUser}
          onChange={setSelectedUser}
          onManageUsersClick={() => setIsUserManagementScreenOpen(true)}
        />
      </div>

      <UserManagementScreen
        expense={expense}
        isOpen={isUserManagementScreenOpen}
        onClose={() => setIsUserManagementScreenOpen(false)}
      />
    </div>
  )
}

function UserSelectionBar(props: {
  users: UserProfile[]
  selectedUser: SelectedUser | null
  onChange: (user: SelectedUser) => void
  onManageUsersClick: () => void
}) {
  const { selectedUser, users, onChange } = props
  return (
    <div className="fixed bottom-0 p-4 w-full">
      <div
        className="px-4 h-14 flex items-center justify-between border rounded-[55px] bg-white/80 backdrop-blur"
        style={{ boxShadow: "0 2px 8px 0 rgba(0,0,0,0.5)" }}
      >
        <div>Claiming items for:</div>
        <UserMenuButton users={users ?? []} onChange={onChange} onMoreClick={props.onManageUsersClick}>
          {selectedUser === "everyone" ? <UserGroupIcon className="w-8 h-8" /> : <Avatar user={selectedUser} />}
          {selectedUser === "everyone"
            ? "Everyone"
            : selectedUser
              ? firstNameLastInitialIfNeeded(selectedUser, users)
              : ""}
        </UserMenuButton>
      </div>
    </div>
  )
}

function UserMenuButton(props: {
  children: ReactNode
  users: UserProfile[]
  onChange: (user: SelectedUser) => void
  onMoreClick: () => void
}) {
  const [isOpen, setIsOpen] = useState(false)

  const handleItemClick = (user: SelectedUser) => {
    setIsOpen(false)
    props.onChange(user)
  }

  const handleMoreUsersClick = () => {
    props.onMoreClick()
    setIsOpen(false)
  }

  return (
    <>
      <button
        type="button"
        className="flex items-center gap-2 text-gray-500 py-2 pl-4 rounded"
        onClick={() => setIsOpen((isOpen) => !isOpen)}
      >
        {props.children}
        <ChevronUpDownIcon className="w-6 h-6" />
      </button>

      {isOpen && (
        <ul className="fixed right-0 bottom-16 shadow-lg rounded border drop-shadow bg-white/95 backdrop-blur">
          {props.users?.map((item) => (
            <MenuItem key={item.id} onClick={() => handleItemClick(item)}>
              <Avatar user={item} />
              {fullName(item)}
            </MenuItem>
          ))}
          {props.users.length > 1 && (
            <MenuItem onClick={() => handleItemClick("everyone")}>
              <UserGroupIcon className="w-8 h-8" />
              Everyone
            </MenuItem>
          )}
          <MenuItem onClick={handleMoreUsersClick} rightIcon={<ChevronRight />}>
            More
          </MenuItem>
        </ul>
      )}
    </>
  )
}

function MenuItem(props: { children: ReactNode; onClick: () => void; rightIcon?: ReactNode }) {
  return (
    <li
      className="min-h-12 border-b last:border-b-0 flex items-center justify-between px-6 pr-2 gap-2 cursor-pointer"
      onClick={props.onClick}
    >
      <div className="flex items-center gap-2 pr-4">{props.children}</div>
      {props.rightIcon}
    </li>
  )
}

export function DetailLine(props: {
  name: string
  children: ReactNode
  details?: ReactNode
  className?: string
  onChange?: (value: string) => void
  editorType?: "text" | "number"
  value?: string | number
}) {
  const [isEditing, setIsEditing] = useState(false)

  const handleEditClick = () => {
    if (props.onChange) setIsEditing(true)
  }

  const submit = (value: string) => {
    setIsEditing(false)
    props.onChange?.(value)
  }

  const handleEditorBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    submit(e.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      submit(e.currentTarget.value)
    }
  }

  const value = props.value ?? props.children
  const editorValue = value === "?" ? "" : value?.toString().replace("$", "") ?? ""

  return (
    <div className={classNames("flex gap-4 items-center px-4 text-[16px]", props.className)}>
      <div className="flex-nowrap whitespace-nowrap">{props.name}</div>
      <hr className="grow border-black/10" />
      <div className="flex items-center gap-0.5 flex-wrap min-w-[15px] min-h-[20px]" onClick={handleEditClick}>
        {isEditing ? (
          <input
            className="w-[80px] text-right -mr-2 pr-2"
            type={props.editorType ?? "text"}
            // biome-ignore lint/a11y/noAutofocus: <explanation>
            autoFocus
            defaultValue={editorValue}
            onBlur={handleEditorBlur}
            onKeyDown={handleKeyDown}
          />
        ) : (
          props.children
        )}
        {props.details && <div className="text-gray-400 text-xs whitespace-nowrap pl-1">{props.details}</div>}
      </div>
    </div>
  )
}

function DetailLineNumber(
  props: Omit<ComponentProps<typeof DetailLine>, "onChange"> & { onChange: (value: number) => void },
) {
  const handleChange = (value: string) => {
    const parsed = Number.parseFloat(value || "0")
    const number = Number.isNaN(parsed) ? 0 : parsed
    props.onChange(number)
  }

  return <DetailLine {...props} editorType="number" onChange={handleChange} />
}
